/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import getSchemaOrgJSONLD from "../getSchemaOrgJSONLD"
function SEO({ description, lang, meta, title }) {
  const { site, logo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        logo: file(relativePath: { eq: "group.png" }) {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    title: "La funcion debe continuar",
    image: `https://lafunciondebecontinuar.com${logo.childImageSharp.original.src}`,
    url: "https://lafunciondebecontinuar.com",
    description: metaDescription,
  })
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: `https://lafunciondebecontinuar.com${logo.childImageSharp.original.src}`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://lafunciondebecontinuar.com${logo.childImageSharp.original.src}`,
        },
        {
          property: `og:url`,
          content: `https://lafunciondebecontinuar.com`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: `https://lafunciondebecontinuar.com${logo.childImageSharp.original.src}`,
        },
        {
          property: "image:alt",
          content: "La funcion debe de continuar",
        },
        {
          property: "og:image:alt",
          content: "La funcion debe de continuar",
        },
        {
          property: "twitter:image:alt",
          content: "La funcion debe de continuar",
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      <link
        async
        href="https://fonts.googleapis.com/css?family=Raleway:300,400,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
