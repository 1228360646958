const getSchemaOrgJSONLD = ({
  url,
  title,
  image,
  description,
  datePublished,
}) => {
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url,
      name: title,
      description,
      alternateName: title,
    },
  ]
  return schemaOrgJSONLD
}

export default getSchemaOrgJSONLD
